import React from 'react'
import Layout, {Grid} from '../components/layout'
import SEO from '../components/seo'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <SEO/>
            <Grid ncol='eight'>
                <h2 style={{marginTop: '20px'}}>プライバシーポリシー</h2>
                <hr/>
                <div>
                    当サイトではアクセス解析のためにGoogle Analyticsを利用しています。
                    <br/>
                    Google Analyticsでは「Cookie」を使用し、個人を特定する情報を含まずにデータを収集します。
                    Google Analyticsの利用により収集されたデータは、Google社のプライバシーポリシーに基づいて管理されます。
                    <br/>
                    <a href="https://policies.google.com/privacy?hl=ja">プライバシー ポリシー – ポリシーと規約 – Google</a>
                </div>
            </Grid>
        </Layout>
    );
}

export default PrivacyPolicy;
